import React from 'react'
import Satin from "../images/satin.png";
import MediaImage from '../images/medyaplan.jpg'; 
import "../styles/Services.css"
import Stats from '../components/Stats';

const Media = () => {
  return (
    <section className="media-planning-section">
        <div className="media-planning-container">
        {/* Left Column: Text */}
        <div className="media-planning-text">
          <h1>Medya Planlama</h1>
          <p>
            Medya planlama, bir kampanyanın reklam bütçesinin doğru medya araçlarına dağıtılması ve en yüksek etkileşimi sağlayacak şekilde kullanılması sürecidir. Markanızın mesajını doğru zamanda, doğru kitleye ulaştırmak için titizlikle çalışıyoruz.
          </p>
          <p>
            Televizyon, dijital medya, basılı yayınlar ve dış mekan reklamları gibi farklı medya araçlarını kullanarak stratejik bir medya planı oluşturuyoruz. Hedef kitlenize en uygun araçlarla markanızı daha geniş kitlelere ulaştırıyoruz.
          </p>
        </div>

        {/* Right Column: Image */}
        <div className="media-planning-image">
          <img src={MediaImage} alt="Media Planning" />
        </div>
      </div>
    <div className="media-planning-container">
      
      {/* Sol Sütun - Başlık ve Metin */}
      <div className="media-planning-content">
        <h1>Medya Satın Alma</h1>
        <p>
          Medya satın alma, hedef kitlenize en etkili şekilde ulaşmak için doğru mecraları belirlemek ve bu mecralarda reklam alanlarını stratejik olarak satın almaktır. MarkaEvi olarak, bütçenizi en verimli şekilde kullanarak reklam kampanyalarınızı maksimum etkiyle yönlendirecek medya planlama ve satın alma hizmeti sunuyoruz.
        </p>
        <p>
          Hedef kitlenizin medya tüketim alışkanlıklarını analiz ederek, televizyon, dijital medya, sosyal medya, radyo ve basılı medya gibi farklı kanallarda markanızın en görünür olacağı noktalara ulaşmanızı sağlıyoruz.
        </p>
      </div>

      {/* Sağ Sütun - Görsel */}
      <div className="media-planning-image">
        <img src={Satin} alt="Medya Satın Alma" />
      </div>
    </div>
  </section> 
   )
}

export default Media