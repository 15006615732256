import React from 'react'
import { Link } from "react-router-dom";
import "../styles/Pre.css"

const PreFooter = () => {
  return (
    <div className="grow-section">
      <div className="grow-column">
      <h2>Markanızı birlikte büyütelim.</h2>

      </div>
      <div className="grow-column">
      <Link to="/services">
      <button className="dark-button">Çalışmalarımız</button>    
 </Link>
 <Link to="/contact">
 <button className="light-button">İletişim</button>   </Link>
    </div>
    </div>
  )
}

export default PreFooter