import React from 'react'
import "../styles/Ref.css"
import { Link } from "react-router-dom";
import Fakir from "../images/fakir.png"
import Avva from "../images/avva.png"
import Hgg from "../images/hgg.jpeg"
import Pronto from "../images/pronto.png"
import Trend from "../images/trend.jpeg"
import Isgyo from "../images/isgyo.png"
import Tds from "../images/tds.webp"
import Seramiksan from "../images/seramik.png"
import Hepsiburada from "../images/hepsi.png"
import Özser from "../images/ozser.png"
import Carrefour from "../images/carrefour.png"
import Evdema from "../images/evdema.png"
import Deposite from "../images/deposite.png"
import EvrimK from "../images/evrim.png"
import Dalga from  "../images/dalga.png"
import Sinpas from "../images/sinpaş.png"
import Yildiz from "../images/yıldaz.png"
import Ramsey from "../images/ramsey.png"
import PierreC from "../images/pierre.png"
import Gig from "../images/gig.png"
import Merit from "../images/merit.png"
import Intex from "../images/intex.png"
import TurkishB from "../images/tb.png"
import Sur from "../images/sur.png"
import Nesine from "../images/nesine.png"
import Uğur from "../images/ugur.png"
import Award from '../components/Award';
import PreFooter from '../components/PreFooter';


const References = () => {
  const references = [
    { id: 1, logo: Fakir, name: 'Fakir', url: 'https://www.fakir.com.tr' },
    { id: 2, logo: Avva, name: 'Avva', url: 'https://www.avva.com.tr' },
    { id: 3, logo: Hgg, name: 'HGG', url: 'https://www.hgg.com.tr' },
    { id: 4, logo: Isgyo, name: 'İş GYO', url: 'https://www.isgyo.com.tr' },
    { id: 5, logo: Pronto, name: 'Pronto Tour', url: 'https://www.prontotour.com' },
    { id: 6, logo: Trend, name: 'Trend GYO', url: 'https://www.trendgyo.com' },
    { id: 7, logo: Tds, name: 'Türkiye Denizciler Sendikası', url: 'https://www.denizciler.org.tr' },
    { id: 8, logo: Seramiksan, name: 'Seramiksan', url: 'https://www.seramiksan.com.tr' },
    { id: 9, logo: Hepsiburada, name: 'HepsiBurada.com', url: 'https://www.hepsiburada.com/' },
    { id: 10, logo: Özser, name: 'Özser NEO', url: 'https://www.ozserneo.com/' },
    { id: 11, logo: Carrefour, name: 'CarrefourSA', url: 'https://www.carrefoursa.com/' },
    { id: 12, logo: Evdema, name: 'Evdema', url: 'https://www.evdema.com/' },
    { id: 13, logo: Deposite, name: 'Deposite', url: 'https://www.deposite.com.tr/' },
    { id: 14, logo: EvrimK, name: 'Evrim Kuran', url: 'https://www.evrimkuran.com/' },
    { id: 15, logo: Dalga, name: 'Dalga Kıran', url: 'https://www.dalgakiran.com/' },
    { id: 16, logo: Sinpas, name: 'Sinpaş', url: 'https://www.sinpas.com.tr/' },
    { id: 17, logo: Yildiz, name: 'Yıldız Entegre', url: 'https://www.yildizentegre.com/' },
    { id: 18, logo: Ramsey, name: 'Ramsey', url: 'https://www.ramsey.com.tr/' },
    { id: 19, logo: PierreC, name: 'Pierre Cardin', url: 'https://www.pierrecardin.com.tr/' },
    { id: 20, logo: Gig, name: 'GİG Sigorta', url: 'https://www.gig.com.tr/' },
    { id: 21, logo: Merit, name: 'Merit Otelleri', url: 'https://www.merithotels.com/' },
    { id: 22, logo: Intex, name: 'Intexcoin.io', url: 'https://www.intexcoin.io/' },
    { id: 23, logo: TurkishB, name: 'Turkish Bank', url: 'https://www.turkishbank.com/' },
    { id: 24, logo: Nesine, name: 'Nesine.com', url: 'https://www.nesine.com/' },
    { id: 25, logo: Sur, name: 'Sur Çelik Kapı', url: 'https://www.surcelikkapi.com/' },
    { id: 26, logo: Uğur, name: 'Uğur Derindondurucu', url: 'https://www.ugur.com.tr/' },


  ];

  return (
    <section className="references-section">
      <div className="highlighted-text">
        <span>Referanslarımız</span>
      </div>  
      
      <div className="references-grid">
        {references.map((ref) => (
          <div className="reference-box" key={ref.id}>
            <img src={ref.logo} alt={`${ref.name} logo`} />
            <div className="spacer"></div> 
            <div className="reference-button">
              <Link href={ref.url} target="_blank" rel="noopener noreferrer">
                {ref.name}'i Ziyaret Et
              </Link>
            </div>
          </div>
        ))}
      </div>

      <Award/>
      <PreFooter/>
    </section>
  );
}

export default References