import React from 'react'
import Image from "../images/webtas.jpg"
import "../styles/Services.css"

const Web = () => {
  return (
<section className="media-planning-section">
      <div className="media-planning-container">
        {/* Left Column: Text */}
        <div className="media-planning-text">
          <h1>Web Yazılım & Tasarım</h1>
          <p>
          Tüm tarayıcılar ve mobil cihazlarla uyumlu, sade ve kolay erişilebilir yapısıyla yeni trend web tasarım hizmetleri vermekteyiz.

</p>
          <p>
          Profesyonel ekibimiz tarafından hazırlanan size özel yazılım çözümleri ile iş süreçlerinizi kolay ve verimli yönetmenize destek olmaktayız.

</p>
        </div>

        {/* Right Column: Image */}
        <div className="media-planning-image">
        <img src={Image} alt="Media Planning" />
        </div>
      </div>
    </section>  )
}

export default Web