import React, { useState } from "react";
import Logo from "../images/logo.png";
import "../styles/Header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="header">
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="Markaevi Logo" />
        </Link>
      </div>

      <div className={`navigation ${isMenuOpen ? "active" : ""}`}>
        <ul>
          <li>
            <Link to="/" onClick={toggleMenu}>Anasayfa</Link>
          </li>
          <li>
            <Link to="/about" onClick={toggleMenu}>Biz Kimiz</Link>
          </li>
          <li>
            <Link to="/services" onClick={toggleMenu}>Neler Yapıyoruz</Link>
          </li>
          <li>
            <Link to="/references" onClick={toggleMenu}>Referanslarımız</Link>
          </li>
        </ul>
      </div>

      <div className="contact-button">
        <Link to="/contact"><button>Bize Ulaşın</button></Link>
      </div>

      {/* Hamburger Menu Button */}
      <div className="hamburger" onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </div>
  );
};

export default Header;