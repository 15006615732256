import React from 'react'
import Image from '../images/yap.jpg'
import "../styles/Services.css"

const Pr = () => {
  return (
    <section className="media-planning-section">
    <div className="media-planning-container">
      <div className="media-planning-text">
        <h1>PR</h1>
        <p>
        Kurumlara iletişim danışmanlığı vererek yeni ve değişik çalışmalar sunma anlayışı içinde, zihinlerde var olanları ustalıkla yönlendirmek bizim işimiz. İletişim alanındaki veri bombardımanı arasından sıyrılarak, toplumda var olan ‘’ulaşılabilirlik sorununu’’ ortadan kaldırmak için çalışıyoruz. Böylece çift yönlü bir iletişim kurarken, tüketicinin zihninde kurumlara ve markalara yer açarak, doğru kanallarla hedeflediğimiz sonuçlara ulaşıyoruz.

</p>
        <p>
        Günümüzde hedef kitleye ulaşmak için sosyal medya mecralarını, blogları, podcastleri, e-postaları ve çok daha fazlasını kullanmak gerekmektedir.

İletişim temelinde multidisipliner bir çalışma gerektiren Dijital PR, markaların dijital dünyanın enstrümanlarını kullanarak, hedef kitleye doğrudan ulaşmalarını ve marka algısını yönetmelerini sağlayan çalışmalar bütünüdür.

Markanın dijital varlıklarının yönetiminden, ürettiği içerik, influencer ve fenomenlerle iş birliklerinden, dijital kanallardaki kampanyalarına, online ve/veya offline etkinliklerinden basın bültenlerinin optimizasyonuna, markanın online itibar yönetimi gibi pek çok konunun stratejiler üzerinden bir bütün olarak ele alınmasını gerektirir.

Hedef kitlenizle etkileşim kurmak için gerekli olan Dijital PR, markanızın online dünyadaki dijital varlığını oluşturmaya, web sitenizin arama görünürlüğünü artırmaya veya marka bilinirliği oluşturmaya yardım eder.          </p>
      </div>

      <div className="media-planning-image">
        <img src={Image} alt="Media Planning" />
      </div>
    </div>
  </section>  )
}

export default Pr