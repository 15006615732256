import React from 'react'
import Image from "../images/reklam.jpg"
import "../styles/Services.css"
const Ad = () => {
  return (
    <section className="media-planning-section">
    <div className="media-planning-container">
      {/* Left Column: Text */}
      <div className="media-planning-text">
        <h1>Reklam 

        </h1>
        <p>
        Müşterilerimiz için geliştirdiğimiz iletişim stratejileri doğrultusunda yaratıcı çalışmalar yürüterek, marka ve kurumlara imaj yaratmak veya pekiştirmek için gerekli tüm çalışmaları hayata geçiriyoruz.

</p>
       
      </div>

      {/* Right Column: Image */}
      <div className="media-planning-image">
        <img src={Image} alt="Media Planning" />
      </div>
    </div>

  </section>  )
}

export default Ad