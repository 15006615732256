import React from 'react'
import "../styles/Sec.css"
import Fakir from "../images/fakir.png";
import Avva from "../images/avva.png";
import Trend from "../images/trend.jpeg";
import Hgg from "../images/hgg.jpeg";
import Pronto from "../images/pronto.png";


const Ref = () => {
  return (
    <div className="references-section">
      <h2>
      Referanslarımız
      </h2>
      <div className="highlighted-ref">
      <span>
      Geleceğe iz bırakıyoruz.
        </span>
      </div>
      <p>Markanızın Hikayesini Yaratın, Biz Dünyaya Anlatmaya Hazırız!</p>

      <div className='logos'>
      <img src={Fakir} alt="Fakir" />
      <img src={Avva} alt="AVVA" />
      <img src={Trend} alt="Trend GYO" />
      <img src={Hgg} alt="HGG" />
      <img src={Pronto} alt="Pronto Tour" />      </div>
    </div>
  )
}

export default Ref