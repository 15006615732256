import React from 'react'
import "../styles/Award.css"
import One from "../images/one.png";

const Award = () => {
  return (
    <div className="awards-section">
      <div className="award-column">
      <h2>
      Ödüllerimiz
  </h2>
  <h3>20 Yıldır Başarıyı Çiziyoruz!</h3>
  <p>
  Müşterimiz Prontotour’a yaptığımız strateji, marka ve PR danışmanlığı
  ile aldığımız ödül;
    </p>
</div>
<div className="award-column">
<img src={One} alt="Award Icon" className="award-icon" />
<h3>The One Awards Bütünleşik Pazarlama Ödülü</h3>

<hr/>
  <p>
  Müşterimiz Prontotour’a İtibarı En Çok Artan Markalar kategorisinde
          “The One Awards Bütünleşik Pazarlama Ödülü” kazandırdık.
       
  </p>
</div>
</div>

)
}

export default Award