import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa"; // Social media icons
import "../styles/Footer.css";
import Logo from "../images/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-columns">
        <div className="footer-column">
          <img src={Logo} to="/" alt="MarkaEvi Logo" className="footer-logo" />
          <p>
            <strong>Adres:</strong> 19 Mayıs Cad. Turhan İş Merkezi No. 25 D.9
            34360 Şişli/İstanbul
          </p>
          <p>
            <strong>Telefon:</strong>
            <a href="tel:+905326673893">+90 532 667 38 93</a>
          </p>
          <p>
            <strong>Email:</strong>
            <a href="mailto:info@markaevi.com.tr">info@markaevi.com.tr</a>
          </p>
        </div>

        <div className="footer-column">
          <h4>Biz Kimiz?</h4>
          <ul>
            <li><Link to="/">Anasayfa</Link> </li>
            <li><Link to="/about">Biz Kimiz</Link></li>
            <li><Link to="/services">Neler Yapıyoruz</Link></li>
            <li><Link to="/references">Referanslarımız</Link></li>
            <li><Link to="/contact">İletişim</Link></li>
          </ul>
        </div>
        {/* Column 3: Neler Yapıyoruz */}
        <div className="footer-column">
          <h4>Neler Yapıyoruz?</h4>
          <ul>
            <li><Link to="/web">Web Tasarım ve Yazılım</Link></li>
            <li><Link to="/ecommerce">E Ticaret</Link></li>
            <li><Link to="/media">Medya Planlama ve Satın Alma</Link></li>
            <li><Link to="/pr">PR</Link></li>
            <li><Link to="/ad">Reklam</Link></li>
          </ul>
        </div>

        {/* Column 4: Social Media */}
        <div className="footer-column">
          <h4>Bizi Takip Edin</h4>
          <div className="social-icons">
            {/* <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a> */}
            <a
              href="https://www.linkedin.com/company/markaevi-pr&advertising&media-planning/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://www.linkedin.com/company/markaevi-pr&advertising&media-planning/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© 2024 MarkaEvi • All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
