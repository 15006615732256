import React from "react";
import illustration from "../images/illustration.png";
import "../styles/Strat.css";
import { Link } from "react-router-dom";

const Strat = () => {
  return (
    <section className="strategic-section">
      <div className="content">
        <div className="strategic-left">
          <h1>
            Stratejik Düşünce, <br />
            Yaratıcı Tasarım, <br />
            Güçlü Reklam: İşte Biz!
          </h1>
          <ul>
            <li>
              <span className="check-icon">✔</span>
              Geleceği şekillendirmek bugünün adımlarını dikkatlice planlamayı
              ve hedeflerimize odaklanmayı gerektirir.{" "}
            </li>
            <li>
              <span className="check-icon">✔</span>
              Yaratıcı tasarım, sıradanı olağanüstü kılmanın anahtarıdır.{" "}
            </li>
            <li>
              <span className="check-icon">✔</span>
              Güçlü reklam, tüketicilerin kalplerine ve zihinlerine yerleşir,
              unutulmaz bir etki bırakır.
            </li>
          </ul>
          <Link to="/services">
            <button className="cta-button">Neler Yapıyoruz? →</button>
          </Link>
        </div>
        <div className="strategic-right">
          <img src={illustration} alt="Illustration" />
        </div>
      </div>
    </section>
  );
};

export default Strat;
