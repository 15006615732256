import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Homepage from "./pages/Homepage";
import About from "./pages/About"
import Ad from "./pages/Ad"
import Contact from "./pages/Contact"
import ECommerce from "./pages/ECommerce"
import Media from "./pages/Media"
import Pr from "./pages/Pr"
import References from "./pages/References"
import Services from "./pages/Services"
import Web from "./pages/Web"

import { Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <div className="App">
      <Header/>
       <Routes>
         <Route path='/' element={<Homepage/>} />
         <Route path='/about' element={<About/>} />
         <Route path='/contact' element={<Contact/>} />
         <Route path="/services" element={<Services/>}/>
         <Route path="/ad" element={<Ad/>}/>
         <Route path="/ecommerce" element={<ECommerce/>}/>
         <Route path="/media" element={<Media/>}/>
         <Route path="/pr" element={<Pr/>}/>
         <Route path="/references" element={<References/>}/>
         <Route path="/web" element={<Web/>}/>
         <Route path="/ad" element={<Ad/>}/>


       </Routes>
       <Footer/>
     </div>
  );
};

export default App;
