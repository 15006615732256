import React from 'react'
import Hero from '../components/Hero'
import Digit from  "../components/Digit"
 import Strat from "../components/Strat"
 import Boxes from '../components/Boxes'
 import Ref from "../components/Ref"
 import Award from "../components/Award"
 import PreFooter from "../components/PreFooter"

const Homepage = () => {
  return (
    <div>
      <Hero/>
    <Digit/>
      <Strat/>
      <Boxes/>
      <Ref/>
      <Award/>
      <PreFooter/> 
    </div>
  )
}

export default Homepage