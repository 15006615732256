import  { useEffect } from 'react'
import React from 'react-dom/client'
import { FaPhone, FaEnvelope } from 'react-icons/fa'; // İkonlar için ekleme
import {
    APIProvider,
    Map,
    Marker,
    useMarkerRef
  } from '@vis.gl/react-google-maps';
  import "../styles/Contact.css"

const Contact = () => {
    const [markerRef, marker] = useMarkerRef();

    useEffect(() => {
      if (!marker) {
        return;
      }
      useEffect
  
      // do something with marker instance here
    }, [marker]);
  
    return (<section>

      <div className="contact-section">
      <div className="contact-info">
          <h2>Bizimle İletişime Geçin</h2>
          <div className="contact-details">
            <div>
              <h3>Adres</h3>
              <p>19 Mayıs, 19 Mayıs Cd. Turhan İş Merkezi No:25 D:9, 34360 Şişli/İstanbul</p>
            </div>
            <div>
              <h3><FaPhone /> Telefon</h3> {/* Telefon ikonunu ekledim */}
              <p><a href="tel:+90532 667 38 93">+90 532 667 38 93</a></p>
            </div>
            <div>
              <h3><FaEnvelope /> Email</h3> {/* Email ikonunu ekledim */}
              <p><a href="mailto:info@markaevi.com.tr">info@markaevi.com.tr</a></p>
            </div>
          </div>
        </div>
  
        <div className="google-maps">
          <h3>Konumumuz</h3>
  
          <APIProvider apiKey={'AIzaSyDJb0LAZnHdbSTHj1vlQ1pS3oHTZqnAotc'}>
            <Map zoom={12} center={{ lat: 41.05888434074644,  lng: 28.992850301959127  }}>
              <Marker ref={markerRef} position={{ lat: 41.05888434074644,  lng: 28.992850301959127 }} />
            </Map>
          </APIProvider>
        </div>
      </div>
    </section>

    );
  };
 
export default Contact