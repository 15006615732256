import React from "react";
import "../styles/Digit.css"

const Digit = () => {
  return (
    <section className="intro-section">
      <div className="intro-left">
        <h1>
          Dijital İzler Bırakın, <br /> Biz Gerisini Hallederiz!
        </h1>
      </div>
      <div className="intro-right">
        <p>
          Hoş geldiniz! 20 yıl boyunca dijital reklamın ön saflarında,
          yaratıcılıkla ve inovasyonla dolu bir yolculukta sizinle birlikte
          olduk. Değişen pazar dinamiklerine uyum sağlamak, güçlü markalar
          yaratmak ve dijital dünyada fark yaratmak adına edindiğimiz deneyimle
          dolu yılların ardından, şimdi sizinle daha büyük başarıları
          hedefliyoruz. Müşteri odaklı yaklaşımımız, yenilikçi çözümlerimiz ve
          kaliteli hizmet anlayışımızla markanızı dijital dünyada zirveye
          taşımak için buradayız. Birlikte, geleceğin reklam stratejilerini
          yazmak ve sizi dijital başarınızın zirvesine taşımak için
          sabırsızlanıyoruz.
     Neler yapıyoruz
          gelin birlikte başaralım.
        </p>
      </div>
    </section>
  );
};

export default Digit;
