import React from 'react'
import Medya from "../images/media.jpg"
import Pr from "../images/pr.jpg"
import Reklam from "../images/ad.jpg"
import Web from "../images/web.jpg"
import ETic from "../images/ecommerce.jpg"
import "../styles/Serv.css"

const Services = () => {
    const services = [
        { image: Medya, title: "Medya Planlama ve Satın Alma", link: "/media" },
        { image: Pr, title: "PR", link: "/pr" },
        { image: Reklam, title: "Reklam", link: "/ad" },
        { image: Web, title: "Web Tasarım ve Yazılım", link: "/web" },
        { image: ETic, title: "E-Ticaret", link: "/ecommerce" }
      ];
    
      return (
        <section className="what-we-do-section">
          {/* Page Title Section */}
          <div className="highlighted-text">
              <span>Neler Yapıyoruz?</span>
            </div>  
    
          {/* Services Grid Section */}
          <div className="services-grid">
            {services.map((service, index) => (
              <div className="service-box" key={index}>
                <img src={service.image} alt={service.title} className="service-image" />
                <h2>{service.title}</h2>
                <button className="learn-more-button">
                  <a href={service.link}>Detaylı Bilgi</a>
                </button>
              </div>
            ))}
          </div>
        </section>
      );
    };

export default Services